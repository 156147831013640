<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import { User } from '~/types/User'
import { Company, CompanyAddress } from '~/types/Company'

const emits = defineEmits(['close', 'finish'])

const { openLogin } = useModals()

const { countryOptions } = storeToRefs(useRemoteConfig())

const { createCompany } = useCompany()
const { showAlert } = useAlert()

const ico = ref<string>('')
const dic = ref<string>('')

const aresLoading = ref<boolean>(false)

const companyCreationLoading = ref<boolean>(false)

const company = reactive({
  name: '',
  address: '',
  city: '',
  zip: '',
  country: '',
})

const users = ref<User[]>([getNewUser()])

const agreed = ref<boolean>(false)

function addUser () {
  users.value.push(getNewUser())
}

function getNewUser (): User {
  return User.newUser()
}

function removeUser (index: number) {
  users.value.splice(index, 1)
}

async function loadAres () {
  aresLoading.value = true
  try {
    const aresData = await $fetch(`/api/ares/${ico.value}`)
    if (!aresData) {
      showAlert({ intent: 'danger', title: 'Nepodařilo se stáhnout informace o firmě' })
      aresLoading.value = false
      return
    }
    company.name = aresData.name
    company.address = aresData.address
    company.city = aresData.city
    company.zip = aresData.zip
    company.country = aresData.country
    dic.value = aresData.dic
    aresLoading.value = false
  }
  catch (e) {
    aresLoading.value = false
  }
}

async function submitForm () {
  companyCreationLoading.value = true
  try {
    await createCompany(Company.fromJson(
      {
        name: company.name,
        id: '',
        invoiceAddress: CompanyAddress.fromJson(({
          ico: ico.value,
          dic: dic.value,
          address: company.address,
          city: company.city,
          zip: company.zip,
          country: company.country,
        }) as Record<string, any>),
        ordersCounter: 0,
        sales: [],
        termsDate: new Date(),
      },
    ), users.value)
    companyCreationLoading.value = false

    emits('finish')
    openLogin()
  }
  catch (e) {
    companyCreationLoading.value = false
    return console.error(e)
  }
}
</script>

<template>
  <VueFinalModal
    class="flex flex-col justify-center items-center py-32px"
    content-class="bg-white p-32px rounded-12px w-full max-w-340px md:max-w-1040px overflow-y-auto md:overflow-y-hidden"
  >
    <div v-auto-animate>
      <div flex items-center justify-between text="24px black">
        <h1 font-700 leading-24px>
          Vytvořit nový účet
        </h1>
        <UnoIcon i-heroicons-solid-x cursor-pointer @click="emits('close')" />
      </div>
      <div flex flex-col md:flex-row items-end gap-16px>
        <KInput
          v-model="ico"
          type="text" name="ico" label="Zadejte IČO společnosti" placeholder="Vaše IČO" focus-on-load required mt-24px w-full
          @keydown.enter="loadAres()"
        />
        <button
          :disabled="aresLoading" flex items-center gap-4px w-full md:w-auto md:mt-0px type="button" app-btn
          :class="{ 'app-btn-loading': aresLoading }" py="10px!" @click="loadAres()"
        >
          Načíst údaje z ARES
          <UnoIcon text="20px" :class="[aresLoading ? 'i-mdi-loading animate-spin' : 'i-heroicons-solid-arrow-right']" />
        </button>
      </div>
      <div bg-app-bg-card rounded-12px p-24px mt-24px>
        <KInput
          v-model="company.name" type="text" name="name" label="Název společnosti"
          placeholder="Název vaší společnosti"
        />
        <KInput v-model="dic" type="text" name="DIC" label="DIČ" placeholder="Zadejete pokud máte DIČ" mt-16px />
        <div grid grid-cols="1 md:2" gap-16px mt-16px>
          <KInput
            v-model="company.address" type="text" name="address" placeholder="Zadejte adresu společnosti"
            label="Adresa"
          />
          <KInput v-model="company.city" type="text" name="city" label="Město" placeholder="Zadejte město" />
          <KInput v-model="company.zip" type="text" name="zip" label="PSČ" placeholder="Zadejte PSČ" />
          <div flex flex-col gap-8px pt-8px>
            <p text-14px leading-16px font-400>
              Země
            </p>
            <KLabelSelect :model-value="countryOptions.find((o) => o.value === company!.country)" :options="countryOptions" full-width do-not-lock @update:model-value="(val) => company!.country = val.value" />
          </div>
        </div>
      </div>
      <div relative mt-24px pt-24px md:pt-0>
        <div hidden md:grid grid-cols="1 md:4" gap-x-16px text-14px leading-16px font-400 text-black ml-2px>
          <div>Jméno <span text-app-red>*</span></div>
          <div>Přijmení <span text-app-red>*</span></div>
          <div>E-mail <span text-app-red>*</span></div>
          <div>Telefon</div>
        </div>
        <UnoIcon
          absolute top--4px right-0 text="24px app-blue" cursor-pointer i-heroicons-solid-plus-circle
          @click="addUser()"
        />
        <div v-auto-animate grid grid-cols="1 md:4" gap-y-8px gap-x-16px mt-16px>
          <template v-for="(user, index) in users" :key="user.id">
            <KInput v-model="user.name" type="text" name="name" placeholder="Jméno" />
            <KInput v-model="user.surname" type="text" name="surname" placeholder="Přijmení" />
            <KInput v-model="user.email" type="text" name="email" placeholder="Email" />
            <div w-full flex items-center gap-8px>
              <KInput
                v-model="user.phone" type="phone" name="phone" placeholder="Telefon"
                :class="{ 'w-full': users.length === 1 }"
              />
              <UnoIcon
                v-if="users.length > 1" text="24px app-red" cursor-pointer i-heroicons-solid-x-circle
                @click="removeUser(index)"
              />
            </div>
          </template>
        </div>
      </div>
      <div flex items-center gap-12px mt-32px whitespace-normal>
        <p text-14px font-400 leading-20px>
          <input
            v-model="agreed" type="checkbox" w-18px h-18px border="2px app-stroke" rounded-sm bg-white
            text-app-blue focus:ring-none cursor-pointer
          >
          <span text-app-text-black ml-12px>Souhlasím s podmínkami a </span>
          <button type="button" text-app-blue>
            obchodními podmínkami a ochranou osobních údajů.
          </button>
        </p>
      </div>
      <div flex items-center justify-center>
        <button
          :disabled="!agreed || companyCreationLoading"
          :class="{ 'opacity-50': !agreed, 'app-btn-loading': companyCreationLoading }" type="button" app-btn uppercase
          py="10px!" w-320px mt-32px self-center @keyup.enter="submitForm()" @click="submitForm()"
        >
          Zaregistrovat se
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>
